.Product {
  color: #54fe05;
  padding: 1rem;
  width: 100%;
  padding: 0;
}

.Product-title {
  font-size: 30px;
  font-weight: bold;
}

.Product-price {
  border: 1px solid currentColor;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 24px;
  padding: 0.6rem;
}

.Product-buy-button {
  display: inline-block;
  margin: 0 0 1rem 0;
  padding: 1em 1em;
  border: 0;
  border-radius: 0;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  background-color: #54fe05;
  color: #000;
  cursor: pointer;
  transition-property: background-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  width: 100%;
  -webkit-appearance: none;
}

.Product-buy-button[disabled] {
  opacity: 0.5;
}

.Product-buy-button:hover,
.Product-buy-button:focus {
  background-color: #fff;
}

.Product-buy-button[disabled]:hover,
.Product-buy-button[disabled]:focus {
  background-color: #54fe05;
}