.Cart {
  border-top: 1px solid currentColor;
  margin: 0 auto;
  max-width: 800px;
  padding-top: 2rem;
  text-align: center;
}

.Cart-total-cost {
  font-weight: 700;
  padding: 2rem;
  margin-bottom: 30px;
  border-bottom: 1px solid currentColor;
}
