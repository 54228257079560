.CheckoutForm {
  border-top: 1px solid #eee;
  margin: 0 auto;
  max-width: 800px;
  padding-top: 2rem;
  text-align: center;
}

.CheckoutForm-complete {
  color: #7fdc45;
  font-weight: 700;
  text-align: center;
}

.CheckoutForm-button {
  display: inline-block;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  border: 0;
  outline: 0;
  border-radius: 100em;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  background-color: #7fdc45;
  color: #fff;
  cursor: pointer;
  transition-property: background-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  -webkit-appearance: none;
}

.CheckoutForm-button:hover,
.CheckoutForm-button:focus {
  background-color: #68b637;
}

.CheckoutForm-button:disabled {
  background-color: #ccc;
}

.CheckoutForm-error {
  color: #dc4545;
}

.StripeElement {
  color: #54fe05;
  display: block;
  margin: 0.5rem auto 1.5rem;
  max-width: 500px;
  padding: 12px 16px;
  font-size: 1rem;
  border: 1px solid currentColor;
  outline: 0;
}

.form-control {
  background-color: transparent;
  border-radius: 0;
  border-color: currentColor;
  color: #54fe05;
}

.labelPrimary {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 22px;
}

.form-control:disabled, .form-control[readonly] {
  background-color: transparent;
}

.pickUpLocation {
  font-size: 16px;
}