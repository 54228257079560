.App-header {
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-text {
  width: 230px;
  padding: 30px 30px 0px 30px;
  color: white;
}

.App-header-text h1 {
  margin-bottom: 10px;
  margin-left: 0;
  letter-spacing: 2px;
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
}

.App-header-text .highlighted {
  color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(139, 199, 249, 1)),
    to(rgba(102, 119, 246, 1))
  );
  -webkit-background-clip: text;
}

.App-shop {
  padding: 1rem 1rem 3rem;
}

.App-products {
  margin: 3rem auto 3.5rem;
  max-width: 800px;
  text-align: left;
}

@media (min-width: 800px) {
  .App-products {
    display: flex;
    justify-content: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
